<template>
  <f7-page class="articleview-page">
    <f7-navbar back-link="Back"></f7-navbar>

    <section class="articleview-container">
      <div v-if="postData?.PostImage" class="article-wrapper">
        <img :src="$h.getImage(postData?.PostImage, 'POST')" :alt="$t.getTranslation('LBL_POST_IMAGE')" loading="lazy" />
      </div>

      <div class="article-content">
        <h1 v-if="postData?.PostTitle" v-html="postData.PostTitle" class="title"></h1>

        <div class="profile">
          <img :src="$h.getImage(postData.UserImage, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" loading="lazy"/>
          <div @click="onViewInfluencer(postData?.UserKey)">
            <h3>{{ postData.UserName }}</h3>
            <p>{{ $h.formatDateFromNow(postData.LastCreated) }}</p>
          </div>

          <f7-button fill small :disabled="$h.checkFollowDisabled(postData?.UserKey)" @click="followToggle(postData?.UserKey)">
            {{ postData?.IsFollow === 1 ? $t.getTranslation("LBL_UNFOLLOW") : $t.getTranslation("LBL_FOLLOW") }}
          </f7-button>
        </div>

        <div class="options">
          <f7-link @click="likeToggle(postData?.PostKey)">
            <font-awesome-icon v-if="postData?.IsLike === 1" :icon="['fas', 'heart']" fixed-width class="fill" />
            <font-awesome-icon v-else :icon="['far', 'heart']" fixed-width />

            <span v-if="postData?.TotalLike > 0 || postData?.TotalDummyLike > 0">{{ $h.formatLikeCounter(postData) }}</span>
            <span v-else>{{ $t.getTranslation("LBL_LIKE") }}</span>
          </f7-link>
          <f7-link @click="showComments">
            <font-awesome-icon :icon="['far', 'comments']" fixed-width />
            <span v-if="postData?.TotalComment > 0">{{ $h.formatDisplayNumber(postData?.TotalComment) }}</span>
            <span v-else>{{ $t.getTranslation("LBL_COMMENT") }}</span>
          </f7-link>

          <f7-link @click="showShare" v-if="showShareBtn">
            <font-awesome-icon :icon="['far', 'share']" fixed-width />
            {{ $t.getTranslation("LBL_SHARE") }}
          </f7-link>

          <f7-link @click="otherActions">
            <font-awesome-icon :icon="['far', 'ellipsis-h']" fixed-width />
          </f7-link>
        </div>

        <div v-if="postData?.PostText" class="content" v-html="postData.PostText"></div>

        <template v-if="postData?.ProductList && postData?.ProductList.length > 0">
          <div v-for="(product, ind) in postData?.ProductList" :key="'pr_' + ind">
            <ProductListCardComponent :data="product" type="select" @selected="selectProduct" />
          </div>
        </template>
      </div>
    </section>

    <f7-actions class="otherActions">
      <f7-actions-group v-if="postData?.IsSelfPost == 1">
        <f7-actions-button @click="editPost">{{ $t.getTranslation("LBL_EDIT") }}</f7-actions-button>
        <f7-actions-button color="red" @click="deletePost">{{ $t.getTranslation("LBL_DELETE") }}</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red" @click="showUserReport">{{ $t.getTranslation("LBL_REPORT") }}</f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <PostCommentComponent v-if="postData && postData.PostKey !== ''" ref="postCommentComponent" :post-data="postData" @updateTotalComment="updateTotalComment" />

    <ShareComponent
      v-if="postData && postData.PostKey !== ''"
      ref="shareComponent"
      :share-data="{
        type: 'POST',
        key: postData.PostKey,
        title: postData.PostTitle || postData.PostText,
        description: postData.PostText,
        qoute: postData.PostText,
        hashtags: postData?.PostTagList,
        media: $h.getImage(postData.PostImage, 'POST'),
      }"
    />

    <UserReportComponent v-if="postData && postData.PostKey !== ''" ref="userReportComponent" :report-key="postData?.PostKey" type="POST" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import PostCommentComponent from "@/components/PostCommentComponent.vue";
// import ShareComponent from "@/components/ShareComponent.vue";
// import UserReportComponent from "@/components/UserReportComponent.vue";

const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));
const PostCommentComponent = defineAsyncComponent(() => import(/* webpackChunkName: "post-comment" */ /* webpackMode: "lazy" */ "@/components/PostCommentComponent.vue"));
const ShareComponent = defineAsyncComponent(() => import(/* webpackChunkName: "share" */ /* webpackMode: "lazy" */ "@/components/ShareComponent.vue"));
const UserReportComponent = defineAsyncComponent(() => import(/* webpackChunkName: "user-report" */ /* webpackMode: "lazy" */ "@/components/UserReportComponent.vue"));

export default defineComponent({
  name: "ArticleViewPage",
  components: {
    ProductListCardComponent,
    PostCommentComponent,
    ShareComponent,
    UserReportComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const postData = ref(false);

    onMounted(() => {
      if (props?.f7route?.params?.key) getPostData(props?.f7route?.params?.key);
    });

    const getPostData = async (key) => {
      let ret = await get("/post/view", { PostKey: key, LanguageCode: $t.getLanguage() });
      postData.value = ret;

      increaseView();
    };

    const otherActions = () => {
      f7.sheet.get(".otherActions").open();
    };

    const onViewInfluencer = (userKey) => {
      props.f7router.navigate({ name: "influencerViewPage", params: { code: userKey } });
    };

    const followToggle = async (UserKey) => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/follow/toggle", { FollowKey: UserKey, Type: "USER" });
      postData.value.IsFollow = postData.value.IsFollow === 1 ? 0 : 1;
    };

    const likeToggle = async (PostKey) => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/post/like/toggle", { PostKey: PostKey });

      if (postData.value.IsLike === 1) {
        postData.value.IsLike = 0;
        postData.value.TotalLike--;
      } else {
        postData.value.IsLike = 1;
        postData.value.TotalLike++;
      }
    };

    const increaseView = async () => {
      let postKey = props?.f7route?.params?.key;
      store.dispatch("view/increase", { type: "POST", key: postKey });
    };

    const updateTotalComment = (count) => {
      postData.value.TotalComment = postData.value.TotalComment + count;
    };

    const editPost = () => {
      props.f7router.navigate({ name: "postUpdatePage", params: { key: postData?.value?.PostKey } });
    };

    const deletePost = () => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      helpers.createConfirmation({
        message: $t.getTranslation("LBL_CONFIRM_DELETE_POST"),
        confirm: async () => {
          await post("/post/delete", {
            PostKey: postData?.value?.PostKey,
          });

          props.f7router.back({ force: true });
        },
      });
    };

    const selectProduct = (data) => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      store.dispatch("view/increase", { type: "POSTPRODUCT", key: data.PostProductId });
      props.f7router.navigate({ name: "productViewPage", params: { code: data.ProductKey } });
    };

    const showShareBtn = ref(true);
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      showShareBtn.value = false;
    }

    return {
      showShareBtn,
      postData,
      otherActions,
      onViewInfluencer,
      followToggle,
      likeToggle,
      updateTotalComment,
      selectProduct,
      editPost,
      deletePost,
    };
  },
  methods: {
    showComments() {
      this.$refs.postCommentComponent.showCommentSheet(this?.postData?.PostKey);
    },
    showShare() {
      this.$refs.shareComponent.showShareSheet();
    },
    showUserReport() {
      this.$refs.userReportComponent.showUserReportPopup();
    },
  },
});
</script>
